const _temp0 = require("./baidu.svg");
const _temp1 = require("./blackberry.svg");
const _temp2 = require("./chrome.svg");
const _temp3 = require("./edge.svg");
const _temp4 = require("./firefox.svg");
const _temp5 = require("./ie.svg");
const _temp6 = require("./kai.svg");
const _temp7 = require("./opera.svg");
const _temp8 = require("./qq.svg");
const _temp9 = require("./safari.svg");
const _temp10 = require("./samsung.svg");
const _temp11 = require("./uc.svg");
module.exports = {
  "baidu": _temp0,
  "blackberry": _temp1,
  "chrome": _temp2,
  "edge": _temp3,
  "firefox": _temp4,
  "ie": _temp5,
  "kai": _temp6,
  "opera": _temp7,
  "qq": _temp8,
  "safari": _temp9,
  "samsung": _temp10,
  "uc": _temp11
}