const _temp0 = require("./1.jpg");
const _temp1 = require("./2.jpg");
const _temp2 = require("./3.jpg");
const _temp3 = require("./4.jpg");
const _temp4 = require("./5.jpg");
const _temp5 = require("./6.jpg");
const _temp6 = require("./7.jpg");
const _temp7 = require("./8.jpg");
const _temp8 = require("./9.jpg");
const _temp9 = require("./10.jpg");
const _temp10 = require("./11.jpg");
const _temp11 = require("./12.jpg");
module.exports = {
  "1": _temp0,
  "2": _temp1,
  "3": _temp2,
  "4": _temp3,
  "5": _temp4,
  "6": _temp5,
  "7": _temp6,
  "8": _temp7,
  "9": _temp8,
  "10": _temp9,
  "11": _temp10,
  "12": _temp11
}