const _temp0 = require("./android.svg");
const _temp1 = require("./ios.svg");
const _temp2 = require("./linux.svg");
const _temp3 = require("./macos.svg");
const _temp4 = require("./others.svg");
const _temp5 = require("./windows.svg");
module.exports = {
  "android": _temp0,
  "ios": _temp1,
  "linux": _temp2,
  "macos": _temp3,
  "others": _temp4,
  "windows": _temp5
}