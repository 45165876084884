const _temp0 = require("./1.jpg");
const _temp1 = require("./2.jpg");
const _temp2 = require("./3.jpg");
const _temp3 = require("./4.jpg");
const _temp4 = require("./5.jpg");
const _temp5 = require("./6.jpg");
module.exports = {
  "1": _temp0,
  "2": _temp1,
  "3": _temp2,
  "4": _temp3,
  "5": _temp4,
  "6": _temp5
}